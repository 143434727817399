import { apiInstance } from "./networking";

export const validatePan = async (payload) => {
  const { data = {} } = await apiInstance.post(`V2/primary/validatePAN`, {enc_request:payload});
  return data;
};

// export const validatePanV2 = async (payload) => {
//   const { data = {} } = await apiInstance.post(`/primary/validatePAN`, {enc_request:payload});
//   return data;
// };


