import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { searchKyc } from "../../../services/customer-service";
import { debounce } from "lodash";
import { decrypt } from "../../../utils/encryption";
import { useSelector } from "react-redux";
import { coKycSearch } from "../../../services/corporate-kycSearch-service";
// import { encrypt,decrypt } from "../../../utils/encryptionV2";

function KYCSearch({
  searchResultHandler = () => {},
  searchTermHandler = () => {},
}) {
  // const [panNumber, setPanNumber] = useState("");
  // const [kycRefId, setKycRefId] = useState("");
  // const [ckycNumber, setCkycNumber] = useState("");
  // const [searchResult, setSearchResult] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const kycTypeTab = useSelector((state) => state.Tabs.kycTypeTab);

  const validateSearchType = (searchTerm) => {
    let searchQuery = {};
    let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
    //set kyc ref id
    searchQuery._id = searchTerm.toLowerCase();
    return searchQuery;
  };
  const fetchRecords = (searchQueryObj) => {

    console.log("kycTypeTab",kycTypeTab, searchQueryObj)
    if (kycTypeTab === "Corporate") {
      coKycSearch(searchQueryObj)
        .then((data) => {
          console.log("in",data)
          if (data?.data?.kyc.length > 0) {
            searchResultHandler(data?.data);
          } else {
            searchResultHandler([])
          }
        })
        .catch((err) => {});
    } else {
      searchKyc(searchQueryObj)
        .then((data) => {
          console.log("in",data)
          // console.log("out", decrypt(data?.data))
          console.log("out222")
          // let dnc = decrypt(data?.data);
          if (data?.data?.kyc.length > 0) {
          console.log("cool")
            // searchResultHandler({ kyc: JSON.parse(dnc) });
            searchResultHandler(data?.data);
          } else {
            searchResultHandler([])
          }
        })
        .catch((err) => {});
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFetchRecords = useCallback(debounce(fetchRecords, 600), []);
  const searchHandler = (e) => {
    e.preventDefault();
    let searchTerm = e.target.value.toUpperCase();
    searchTermHandler(searchTerm);
    setSearchTerm(searchTerm);
    if (!e.target.value) {
      searchTermHandler("");
      return;
    } else {
      let searchQueryObj = validateSearchType(searchTerm);
      if (searchQueryObj) {
        debounceFetchRecords(searchQueryObj);
      }
    }
  };

  useEffect(() => {
    if (!searchTerm) debounceFetchRecords.cancel();
  }, [debounceFetchRecords, searchTerm]);

  return (
    <form className="lato-family">
      <label
        htmlFor="default-search"
        className="mb-5 text-xs font-medium text-gray-500"
      >
        Search
      </label>
      <div className="relative">
        <div className=" flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block  p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-sm border border-gray-300 
          focus:outline-none focus:border-gray-400 md:w-[100%]"
          placeholder="Eg. KYC Ref No. / Pehchaan ID"
          required
          onChange={searchHandler}
          value={searchTerm}
        />
      </div>
      {searchTerm && (
        <p className="text-xs mt-2 ">Showing Records for {searchTerm}</p>
      )}
    </form>
  );
}

export default KYCSearch;
