import { createSlice } from "@reduxjs/toolkit";

export const partnerChannelNameSlice = createSlice({
  name: "channelName",

  initialState: {
    marutiUserDetails: { channelUrl: "", typeOfUI: "", mi_u: "", due: null, customerType: "I",subChannel:"" },
  },
  reducers: {
    updateMarutiUserDetails: (state, action) => {
      state.marutiUserDetails = {
        ...state.marutiUserDetails,
        ...action.payload,
      };
    },
  },
});

export const {
  channelUrl,
  typeOfUISetter,
  dueDeligenceTxnIdhandler,
  updateMarutiUserDetails,
} = partnerChannelNameSlice.actions;
export default partnerChannelNameSlice.reducer;
