import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tab from "../../Components/kyc-components/Tab";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
// import PanCardFrame from "./Frames/PanCardFrame";
import styles from "./KYCHomeScreen.module.css";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";

import Loadingoverlay from "../../Components/global-components/LoadingOverlay/Loadingoverlay";

// import CommonDropDown from "../../Components/global-components/CommonDropDown/CommonDropDown";
import TextInput from "../../Components/global-components/CommonTextInput/TextInput";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
// import { coInDigitalService } from "../services/individual-digital-service";
import { coInDigitalService } from "../../services/individual-digital-service";
import { toggleTabs } from "../Redux/slice";
import { gaFunction } from "../../Router";
import OtherDocsFrame from "./Frames/OtherDocsFrame";

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      color: "black",
    },
    "& .MuiSelect-select": {
      padding: "8px 16px",
    },
  },
});

const documnetValues = [
  { label: "Pan", value: "PAN" },
  { label: "CKYC", value: "CKYC" },
  { label: "Aadhaar", value: "AADHAAR" },
  { label: "Passport", value: "PASSPORT" },
  { label: "Voter ID", value: "VOTERID" },
  { label: "Driving Licence", value: "DL" },
];

const KYCHomeScreen = () => {
  console.log("RERENDER THE PAGE");

  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();

  // console.log(digitalForm60Id)

  const [docType, setDocType] = useState("PAN");
  const [radioValue, setRadioValue] = useState("M");
  const [docValue, setDocValue] = useState("");
  const [getName, setName] = useState("");
  const [getDOB, setDOB] = useState("");
  const [getFullAddress, setFullAddress] = useState("");
  const [pincode, setPinCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const { otherdocs } = state || {};

  const [getOtherdocs, setShowOtherDocs] = useState(otherdocs || false);
  const maxDate = new Date().toISOString().split("T")[0];

  const clickHandler = () => {
    // gaFunction("OVD", "OVD Clicked");
    // setShowOtherDocs(true);
    // dispatch(toggleTabs("otherDocs"));
  };

  function changedateformat(val) {
    const myArray = val.split("-");
    let year = myArray[0];
    let month = myArray[1];
    let day = myArray[2];
    let formatteddate = day + "-" + month + "-" + year;
    return formatteddate;
  }

  const handleProceed = (e) => {
    e.preventDefault();

    let checkCondition =
      docType === "AADHAAR"
        ? docValue !== "" &&
          getName !== "" &&
          getDOB !== "" &&
          getFullAddress !== "" &&
          pincode !== ""
        : docValue !== "" && getFullAddress !== "" && pincode !== "";

    if (checkCondition) {
      let formatedDOB = changedateformat(getDOB);

      let data = JSON.stringify({
        id_type: docType,
        id_num: docValue,
        full_name: getName,
        gender: radioValue,
        dob: formatedDOB,
        permanent_address: getFullAddress,
        permanent_pincode: pincode,
        customer_type: "I",
      });
      // let data = {
      //   id_type: docType,
      //   id_num: docValue,
      //   full_name: getName,
      //   gender: radioValue,
      //   dob: formatedDOB,
      //   permanent_address: getFullAddress,
      //   permanent_pincode: pincode,
      //   customer_type: "I",
      // };

      console.log(data);
      setIsLoading(true);
      coInDigitalService(data)
        .then((response) => {
          console.log(response);
          if (response.status) {
            navigate("/Success", {
              state: {
                kyc_ref_id: response?.kycRefId,
              },
            });
          } else {
            setIsLoading(false);
            console.log("NO Record found");
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });

      console.log(data);
      setErrorMessage("");
      console.log({
        docType,
        radioValue,
        docValue,
        getName,
        getDOB,
        getFullAddress,
        pincode,
      });
    } else {
      setErrorMessage("Please fill all fields");
      setIsLoading(false);
    }
  };

  const [docTypeValidationError, setDocTypeValidationError] = useState("");

  const handleDocTypeRegex = (e, docType) => {
    if (docType === "PAN") {
      let value = e.target.value.toUpperCase();
      let regexPan = /[A-Z]{5}\d{4}[A-Z]{1}/i;
      value = value?.replace(/\s/g, "");
      if (value.length <= 10) {
        setDocValue(value);
      }
      setDocTypeValidationError(null);

      if (!regexPan.test(value)) {
        setDocTypeValidationError("Invalid Pan Number");
      }
    } else if (docType === "AADHAAR") {
      const numReg = /^\d{0,12}$/;
      if (numReg.test(e.target.value)) {
        setDocValue(e.target.value);
      }
    } else if (docType === "CKYC") {
      const numReg = /^\d{0,14}$/;
      if (numReg.test(e.target.value)) {
        setDocValue(e.target.value);
      }
    } else if (docType === "PASSPORT") {
      let value = e.target.value.toUpperCase();
      let regexPan = /[A-Z]{1}\d{7}/i;
      value = value?.replace(/\s/g, "");
      if (value.length <= 8) {
        setDocValue(value);
      }
      setDocTypeValidationError(null);

      if (!regexPan.test(value)) {
        setDocTypeValidationError("Invalid Passport Number");
      }
    } else if (docType === "DL") {
      let value = e.target.value.toUpperCase();
      let regexPan = /[A-Z]{2}\d{13}/i;
      value = value?.replace(/\s/g, "");
      if (value.length <= 15) {
        setDocValue(value);
      }
      setDocTypeValidationError(null);

      if (!regexPan.test(value)) {
        setDocTypeValidationError("Invalid Driving Licence Number");
      }
    } else if (docType === "VOTERID") {
      let value = e.target.value.toUpperCase();
      let regexPan = /[A-Z]{3}\d{7}/i;
      value = value?.replace(/\s/g, "");
      if (value.length <= 10) {
        setDocValue(value);
      }
      setDocTypeValidationError(null);

      if (!regexPan.test(value)) {
        setDocTypeValidationError("Invalid vOTER ID");
      }
    }
  };

  console.log(docTypeValidationError);

  const handleGetInputValue = (e, type, docType) => {
    if (type === "doc_Value") {
      handleDocTypeRegex(e, docType);
    } else if (type === "full_name") {
      const regex = /^[A-Za-z\s]+$/;
      if (regex.test(e.target.value) || e.target.value === "") {
        setName(e.target.value);
      }
    } else if (type === "full_address") {
      const regex = /^[A-Za-z0-9,/-:\s]+$/;
      if (regex.test(e.target.value) || e.target.value === "") {
        setFullAddress(e.target.value);
      }
    } else if (type === "pincode") {
      const numReg = /^\d{0,6}$/;
      if (numReg.test(e.target.value)) {
        setPinCode(e.target.value);
      }
    }
  };

  const handleRadioValueChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleChange = (event) => {
    setDocType(event.target.value);
    setDocTypeValidationError(null);
  };

  const handlerDateChange = (event) => {
    setDOB(event.target.value);
  };

  return (
    <>
      {isLoading ? (
        <Loadingoverlay />
      ) : (
        <div className={styles["kycHome_container"]}>
          <BannerImage>
            <LogoContainer />
          </BannerImage>

          <MsHome>
            <div className="bg-secondaryH md:h-[100%] md:overflow-hidden">
              <BottomCard stepper={false}>
                <>
                  <div className="md:flex-[0_0_auto]">
                    <label className="md:text-lg md:hidden sm:block">
                      <b>KYC Document</b>
                    </label>
                    <p className="text-sm md:hidden sm:block">
                      Let's fetch your kyc details using
                    </p>
                  </div>
                  {/* OVD FLOW */}

                  {/* {!digitalForm60Id ? (
                    <div>
                      <Tab />
                    </div>
                  ) : (
                    ""
                  )} */}

                  <div className="md:flex-[1_0_0] md:overflow-hidden">
                    {getOtherdocs ? (
                      <div className="w-full h-full   md:flex md:flex-col md:w-[70%]">
                        <OtherDocsFrame />
                      </div>
                    ) : (
                      <div className="h-full w-full md:flex md:flex-col  md:w-[100%]">
                        <div className="md:overflow-scroll md:p-0 ">
                          <div className=" md:w-[300px]">
                            <FormControl
                              sx={{
                                width: "100%",
                                marginY: 1,
                                fontFamily: "Montserrat",
                              }}
                              classes={classes}
                            >
                              <p className="lato-family mb-[5px] font-medium">
                                Select Type of Document:
                              </p>
                              <Select
                                className="lato-family"
                                value={docType || "PAN"}
                                onChange={handleChange}
                                defaultValue={"PAN"}
                              >
                                {documnetValues.map((ele, index) => {
                                  return (
                                    <MenuItem value={ele?.value}>
                                      {ele?.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </div>

                          <div className="mt-5">
                            <TextInput
                              label={
                                docType === "PAN"
                                  ? "Pan Number:"
                                  : docType === "CKYC"
                                  ? "CKYC Number:"
                                  : docType === "AADHAAR"
                                  ? "Aadhar Number:"
                                  : docType === "PASSPORT"
                                  ? "Passport Number:"
                                  : docType === "VOTERID"
                                  ? "Voter Id Number:"
                                  : docType === "DL"
                                  ? "Driving Licence Number:"
                                  : ""
                              }
                              width="md:w-[300px]"
                              placeholder={
                                docType === "PAN"
                                  ? "Ex. AIYPM1234J"
                                  : docType === "CKYC"
                                  ? "Ex. 12345678901234"
                                  : docType === "AADHAAR"
                                  ? "Ex. 000011110000"
                                  : docType === "PASSPORT"
                                  ? "Ex. A1234567"
                                  : docType === "VOTERID"
                                  ? "Ex. AAB1234567"
                                  : docType === "DL"
                                  ? "Ex. AB001100110011"
                                  : ""
                              }
                              name="docValue"
                              value={docValue}
                              onChange={(e) =>
                                handleGetInputValue(e, "doc_Value", docType)
                              }
                            />
                            <p class="text-red-500 text-sm">
                              {docTypeValidationError}
                            </p>
                          </div>

                          {docType === "AADHAAR" && (
                            <div className="mt-5">
                              <TextInput
                                data-tag={"2"}
                                label={"Full Name:"}
                                width="md:w-[300px]"
                                placeholder={"Enter your name as per document"}
                                value={getName}
                                // onChange={(e) => setName(e.target.value)}
                                onChange={(e) =>
                                  handleGetInputValue(e, "full_name")
                                }
                              />
                            </div>
                          )}
                          <div className="mt-5">
                            <TextInput
                              label={"Date of Birth:"}
                              width="md:w-[300px]"
                              placeholder={"Date of Birth:"}
                              type="date"
                              max={maxDate}
                              value={getDOB}
                              onChange={handlerDateChange}
                            />
                          </div>

                          {docType === "AADHAAR" && (
                            <div className="mt-5">
                              <label>Gender:</label>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleRadioValueChange}
                              >
                                <FormControlLabel
                                  value="M"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                          {
                                            color: "#E42125",
                                          },
                                        "& .MuiSvgIcon-root + .MuiSvgIcon-root":
                                          {
                                            color: "#E42125",
                                          },
                                      }}
                                    />
                                  }
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="F"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                          {
                                            color: "#E42125",
                                          },
                                        "& .MuiSvgIcon-root + .MuiSvgIcon-root":
                                          {
                                            color: "#E42125",
                                          },
                                      }}
                                    />
                                  }
                                  label="Female"
                                />
                                <FormControlLabel
                                  value="T"
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                          {
                                            color: "#E42125",
                                          },
                                        "& .MuiSvgIcon-root + .MuiSvgIcon-root":
                                          {
                                            color: "#E42125",
                                          },
                                      }}
                                    />
                                  }
                                  label="Other"
                                />
                              </RadioGroup>
                            </div>
                          )}

                          <div className="mt-5">
                            <TextInput
                              width="md:w-[300px]"
                              label={"Full Address:"}
                              placeholder={"Full Address"}
                              value={getFullAddress}
                              // onChange={(e) => setFullAddress(e.target.value)}
                              onChange={(e) =>
                                handleGetInputValue(e, "full_address")
                              }
                            />
                          </div>
                          <div className="mt-5">
                            <TextInput
                              width="md:w-[300px]"
                              label={"Pincode:"}
                              placeholder={"Pincode"}
                              // maxlen={6}
                              // max={6}
                              value={pincode}
                              // onChange={(e) => setPinCode(e.target.value)}
                              onChange={(e) =>
                                handleGetInputValue(e, "pincode")
                              }
                            />
                          </div>
                        </div>
                        {/* <p className="text-red-500 text-sm mt-5" id="errorMsg"> */}
                        <p className="mt-2 text-center text-red-500 md:w-[300px]">
                          {errorMessage}
                        </p>
                        <div className="w-full mt-5 md:mt-0 md:w-[300px] flex md:pb-1">
                          <CommonButton
                            label="Proceed"
                            filled
                            arrow
                            // loading={isButtonLoading}
                            id={"toCompletePanKyc"}
                            onClickHandler={(e) => {
                              handleProceed(e);
                            }}
                          />
                        </div>
                        {docValue == "" &&
                          getName == "" &&
                          getDOB == "" &&
                          getFullAddress == "" &&
                          pincode == "" && (
                            <>
                              <div className="mt-2 mb-2 text-center underline text-blue-500 md:w-[300px]">
                                OR
                              </div>
                              <div className="w-full  md:mt-0 md:w-[300px] flex md:pb-1">
                                <CommonButton
                                  label="Use other documents to submit your KYC"
                                  filled
                                  arrow
                                  // loading={isButtonLoading}
                                  id={"ovd"}
                                  onClickHandler={clickHandler}
                                />
                              </div>
                            </>
                          )}
                      </div>
                    )}
                  </div>
                </>
              </BottomCard>
            </div>
          </MsHome>
        </div>
      )}
    </>
  );
};

export default KYCHomeScreen;
