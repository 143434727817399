import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { gaFunction } from "../../Router";
import { marutiUrl } from "../../services/maruti-service";
import { getBinaryUserDataPanDob } from "../../services/vendorServices/binary-service";
import { storeToken } from "../Redux/authSlice";
import { showGlobalError } from "../Redux/globalErrorSlice";
import { updateMarutiUserDetails } from "../Redux/partnerChannelNameSlice";
import { updateUserData } from "../Redux/userData";

const BinaryLandingPage = () => {
  const { channelUrl: storedChannelname } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const { token } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //binary login
    if (window.location.pathname === "/binary") {
      let channelName;
      let mTxn = "";
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      channelName = urlParams.get("channel");
      gaFunction("binary", "binary logged in");
      mTxn = urlParams.get("txnId");
      let subChannel = urlParams.get("subChannel");
      console.log("subChannel",subChannel)
      updateUserData({
        panNumber: "",
        dob: "",
      });
      dispatch(
        updateMarutiUserDetails({
          channelUrl: channelName,
          mi_u: mTxn,
          subChannel
        })
      );
      dispatch(storeToken(urlParams.get("token")));
      localStorage.setItem("txnId", urlParams.get("txnId"));
      localStorage.setItem("token", urlParams.get("token"));
      localStorage.setItem(
        "redirectUrl",
        decodeURIComponent(urlParams.get("redirectUrl"))
      );
      localStorage.setItem("channel", urlParams.get("channel"));
      localStorage.setItem(
        "callbackUrl",
        decodeURIComponent(urlParams.get("callbackUrl"))
      );
      if (channelName !== null || channelName?.length > 0) {
        if (channelName === "binary") {
          getBinaryUserDataPanDob({
            txnId: mTxn || "",
            channelUrl: channelName || "",
          })
            .then((response) => {
              if (response?.success === true) {
                if (Object.keys(response?.data)?.length > 1) {
                  dispatch(
                    updateUserData({
                      panNumber: response?.data?.pan,
                      dob: response?.data?.dob
                        ?.split("/")
                        ?.join("-"),
                    })
                  );
                }
              }
            })
            .catch((err) => {
              dispatch(
                showGlobalError("Something went wrong. Please try again")
              );
              navigate("/*");
            });
        }
        // navigate("/kyc");
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (storedChannelname === "binary" && token) {
      setTimeout(() => {
        navigate("/kyc", { replace: true });
      }, 500)
      navigate("/kyc", { replace: true });
    }
  }, [storedChannelname, token]);

  return <></>;
};

export default BinaryLandingPage;
