import { apiInstance } from "./networking";

// export const getCustomerAllKyc = async (payload) => {
//   const { data = {} } = await apiInstance.get(
//     `/customer/get-customer-all-kyc`
//   );
//   return data;
// };
export const getCustomerAllKyc = async (payload) => {
  const { data = {} } = await apiInstance.get(
    `/customer/get-customer-all-kyc`
  );
  return data;
};

export const searchKyc = async (payload) => {
  const { _id } = payload;
  const { data = {} } = await apiInstance.get(
    `/customer/search-kyc?&kycRefId=${_id.toUpperCase()}`
  );
  return data;
};
