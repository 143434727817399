import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import TextInput from "../../../Components/global-components/CommonTextInput/TextInput";
import MenuItem from "@mui/material/MenuItem";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toggleTabs } from "../../Redux/slice";
import { toggleButton } from "../../Redux/switchToggleSlice";
import BannerImage from "../../../Components/login-components/banner-image/BannerImage";
import LogoContainer from "../../../Components/login-components/logo-container/LogoContainer";
import MsHome from "../../../Components/Maruti-Suzuki-components/MsHome";
import BottomCard from "../../../Components/login-components/bottom-card/BottomCard";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { form60Upload } from "../../../services/form60-upload-service";
import PreviewDocument from "../Frames/PreviewDocument";
import ImageCrop from "./CropImageFormSixty/ImageCrop";
import Tooltip from "@mui/material/Tooltip";
import { optionsValue } from "../../Redux/otherdocsDropDownValueSlice";
import {
  clearFormSixtyDataState,
  clearState,
  digitalData,
  formSixtyData,
} from "../../Redux/digitalFormSixtySlice";
import { formValidator } from "../../../Router";
import { showGlobalError } from "../../Redux/globalErrorSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Loadingoverlay from "../../../Components/global-components/LoadingOverlay/Loadingoverlay";

const Digitalformsixty = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [signaturepreview, setSignaturePreview] = useState(false);
  const [aadhaarValidationError, setAadhaarValidationError] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [confirmSubmitDialog,setConfirmSubmitDialog]=useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { methodOfKyc } = state || {};
  const {
    userFirstName,
    userMiddleName,
    userSurName,
    dateOfBirth,
    fatherFirstName,
    fatherMiddleName,
    fatherSurName,
    roomNo,
    floorNo,
    PremisesName,
    BlockNo,
    area,
    town,
    district,
    states,
    pinCode,
    telePhoneNumber,
    mobileNumber,
    premiumAmount,
    dateOfTransaction,
    numberOfPerson,
    modeOfTransaction,
    aadhaarNumber,
    appliedForPan,
    dateOfApplication,
    acknowledgementNumber,
    agriculturalIncome,
    otherAgriculturalIncome,
    placeWhereSigned,
    signatureFile,
    signatureName,
    documentCode,
    authorityName,
    digitalForm60Id,
    documentIdentificationNumber,
    formSixtyString,
    selectedDoc,
  } = useSelector((state) => state.digitalFormSixty.formSixtyData);

  const formatDate=(value)=>{
    const parts = value.split('-');
    return parts.reverse().join('-');
  }

  const otherDocsDropDownValue = useSelector(
    (state) => state.dropDownValue.selectedValue
  );

  const digitalFormSixtyData = {
    state: states,
    city: town,
    addressLine1: area,
    addressLine2: town,
    firstName: userFirstName,
    middleName: userMiddleName,
    lastName: userSurName,
    fatherFirstName: fatherFirstName,
    fatherMiddleName: fatherMiddleName,
    fatherLastName: fatherSurName,
    flatOrRoomNo: roomNo,
    floorNo: floorNo,
    nameOfPremises: PremisesName,
    blockNameOrNo: BlockNo,
    roadOrStreetOrLane: "",
    areaOrLocality: area,
    townOrCity: town,
    district: district,
    pinCode: pinCode,
    telephoneNumberWithStdCode: telePhoneNumber,
    mobileNumber: mobileNumber,
    amountOfTransactionInRs: premiumAmount,
    numberOfPerson: numberOfPerson,
    aadhaarNumber: aadhaarNumber,
    agriculturalIncomeInRs:agriculturalIncome,
    otherThanAgriculturalIncomeInRs: otherAgriculturalIncome,
    dateOfBirth: formatDate(dateOfBirth),
    dateOfTransaction:formatDate(dateOfTransaction), 
    dateOfApplicationIfPanNotGenerated: formatDate(dateOfApplication || ""),
    panAcknowledgementNumber: acknowledgementNumber,
    modeOfPayment:modeOfTransaction,
    placeWhereSigned: placeWhereSigned,
    documentCode: documentCode,
    authorityName: authorityName,
    signatureImageBuffer: signatureFile,
    documentIdentificationNumber:documentIdentificationNumber,
  };

  let formSixtyValidationForm = [
    { fieldLength: userFirstName?.trim()?.length, fieldName: "First Name" },
    // { fieldLength: userMiddleName?.trim()?.length, fieldName: "Middle Name" },
    { fieldLength: userSurName?.trim()?.length, fieldName: "Surname" },
    { fieldLength: dateOfBirth?.trim()?.length, fieldName: "Date of Birth" },
    { fieldLength: fatherFirstName?.length, fieldName: "Father First Name" },
    // {
    //   fieldLength: fatherMiddleName?.trim()?.length,
    //   fieldName: "Father Midldle Name",
    // },
    { fieldLength: fatherSurName?.trim()?.length, fieldName: "Father Surname" },
    { fieldLength: roomNo?.trim()?.length, fieldName: "Room No" },
    // { fieldLength: floorNo?.trim()?.length, fieldName: "Floor No" },
    // { fieldLength: PremisesName?.trim()?.length, fieldName: "Premises Name" },
    // { fieldLength: BlockNo?.trim()?.length, fieldName: "Block No" },
    { fieldLength: area?.trim()?.length, fieldName: "area" },
    { fieldLength: town?.trim()?.length, fieldName: "town" },
    { fieldLength: district?.trim()?.length, fieldName: "district" },
    { fieldLength: states?.trim()?.length, fieldName: "states" },
    { fieldLength: pinCode?.trim()?.length, fieldName: "pinCode" },
    { fieldLength: mobileNumber?.trim()?.length, fieldName: "mobile Number" },
    { fieldLength: premiumAmount?.trim()?.length, fieldName: "premium Amount" },
    {
      fieldLength: dateOfTransaction?.trim()?.length,
      fieldName: "Date of Transaction",
    },

    {
      fieldLength: modeOfTransaction?.trim()?.length,
      fieldName: "mode Of Transaction",
    },
    {
      fieldLength: appliedForPan?.trim()?.length,
      fieldName: "Applied for PAN or not.",
    },
    {
      fieldLength: placeWhereSigned?.trim()?.length,
      fieldName: "Place Where Signed",
    },
    { fieldLength: signatureFile?.trim()?.length, fieldName: "Signature File" },
  ];

  const backHandler = () => {
    if(methodOfKyc!=="corporateProprietor"){
    navigate("/kyc");
    dispatch(
      toggleTabs(
        methodOfKyc === "pan"
          ? "pan"
          : methodOfKyc === "aadhaar"
          ? "aadhaar"
          : "otherDocs"
      )
    );
   }else {
    navigate("/corporatePage")
   }
    dispatch(toggleButton(true));
    dispatch(clearFormSixtyDataState());
  };

  const setDocumentCode = (value) => {
    if (value == "aadhaar") {
      dispatch(
        formSixtyData({
          documentCode: "01",
        })
      );
    } else if (value == "maskedAadhaar") {
      dispatch(
        formSixtyData({
          documentCode: "01",
        })
      );
    }
    else if (value == "drivingLicence") {
      dispatch(
        formSixtyData({
          documentCode: "05",
        })
      );
    }  else if (value == "passport") {
      dispatch(
        formSixtyData({
          documentCode: "06",
        })
      );
    } else if (value == "voterid") {
      {
        dispatch(
          formSixtyData({
            documentCode: "03",
          })
        );
      }
    }
  };

  const onSubmitHandler = async () => {
    const emptyField = formValidator(formSixtyValidationForm);
    if (emptyField) {
      dispatch(showGlobalError("Please fill this detail : " + emptyField));
      return;
    }


    if (pinCode.length != 6) {
      dispatch(
        showGlobalError(
          "PinCode must be of 6 Numbers"
        )
      );
      return;
    }

    
    if (mobileNumber.length < 10) {
      dispatch(
        showGlobalError(
          "Mobile number must be of 10 Numbers"
        )
      );
      return;
    }

    if (aadhaarNumber.length != "" && aadhaarNumber.length != 12) {
      dispatch(
        showGlobalError(
          "Invalid Aadhaar Number"
        )
      );
      return;
    }

    if(otherDocsDropDownValue=="aadhaar" || otherDocsDropDownValue=="maskedAadhaar" ){
      if (aadhaarNumber.length == "" || aadhaarNumber.length != 12) {
        setAadhaarValidationError("Please Enter valid Aadhaar Number");
        dispatch(
          showGlobalError(
            "Please Enter Aadhaar number"
          )
        );
        return;
      }
    }

    if (
      appliedForPan === "false" &&
      (agriculturalIncome == "" && otherAgriculturalIncome == "" )
    ) {
      dispatch(
        showGlobalError(
          "Please fill this detail : " + "Other then AgriculturalIncome or AgriculturalIncome "
        )
      );
      return;
    }
    // `${agriculturalIncome ? "Other then AgriculturalIncome" : "AgriculturalIncome"}`

    if (
      appliedForPan === "true" &&
      (dateOfApplication == "" || acknowledgementNumber == "")
    ) {
      dispatch(
        showGlobalError(
          "Please fill this detail : " +
            "Date of Application And Acknowledgement Number"
        )
      );
      return;
    }

    if (
      (aadhaarNumber === "" && appliedForPan === "false") ||
      (aadhaarNumber !== "" && appliedForPan === "false") ||
      !(aadhaarNumber !== "" && appliedForPan === "true")
    ) {
      if(documentIdentificationNumber.length < 4 ){
        dispatch(
          showGlobalError(
            "Please fill this detail : " +
              "Please Enter Document Identification Number"
          )
        );
        return;
      }
    }
    if(dateOfBirth!==""){
      console.log(dateOfBirth)
       var selectedDate = new Date(dateOfBirth);
       var currentDate = new Date();
       var minDate = new Date();
       minDate.setFullYear(currentDate.getFullYear() - 18);
       const year100YearsAgo = currentDate.getFullYear() - 100;
       const lastDateOfDOB =  new Date(year100YearsAgo, currentDate.getMonth(), currentDate.getDate());
       console.log(selectedDate>lastDateOfDOB )
       if(selectedDate < lastDateOfDOB || selectedDate > currentDate){
        dispatch(
          showGlobalError(
            `Error: Select a date from ${lastDateOfDOB} or later, not in the future.`
          )
        );
        return;
      }
      else if (selectedDate > minDate) {
       dispatch(
        showGlobalError(
          "Minimum 18 years age should be applicable."
        )
      );
      return;
    }
    }

  

    setConfirmSubmitDialog(!confirmSubmitDialog);
  };

  const modeOfFunc = (e) => {
    console.log(e.target.value);
    dispatch(formSixtyData({ modeOfTransaction: e.target.value }));
  };

  const handlePanStatus = (e) => {
    if(e.target.value=="true"){
      dispatch(
        formSixtyData({
          agriculturalIncome: "",otherAgriculturalIncome: "",
        })
      );
      if(aadhaarNumber!=""){
        dispatch(optionsValue("aadhaar"));
        dispatch(
          formSixtyData({
            selectedDoc: "aadhaar",
          })
        );
        setSelectedDocument("aadhaar");
        setDocumentCode("aadhaar");
      }
    }else if(e.target.value=="false"){
      dispatch(
        formSixtyData({
          dateOfApplication: "",acknowledgementNumber: "",
        })
      );
    }
    dispatch(
      formSixtyData({
        appliedForPan: e.target.value,
      })
    );
  };

  const closePreview = () => {
    setSignaturePreview(false);
  };

  const openSignatureImageHandler = () => {
    setSignaturePreview(true);
  };
  const statNames = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Chandigarh",
    "Dadra & Nagar Haveli",
    "Daman & Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu & Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Pondicherry",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Tripura",
    "Uttar Pradesh",
    "Telangana",
    "Uttarakhand",
    "West Bengal",
    "State Outside India"
  ];

  function capitalizeFirstLetter(str) {
    if (typeof str !== 'string') {
      return str; // Return the input unchanged if it's not a string
    }
  
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const maxDate = new Date().toISOString().split("T")[0];
  
  const lastDate = new Date("1900-01-01").toISOString().split("T")[0]; 


  return (<>
    { isLoading ? (
      <Loadingoverlay />
    ) : (
    <React.Fragment>
      <BannerImage>
        <LogoContainer />
      </BannerImage>
      <MsHome>
        <div className="bg-secondaryH md:h-[100%] md:overflow-hidden">
          <BottomCard>
            <KeyboardBackspaceIcon
              className="cursor-pointer mb-[10px]"
              onClick={backHandler}
            />
            <div className="md:h-full overflow-hidden overflow-y-scroll">
              <div className="md:flex block mr-2 md:justify-around md:gap-[17px]">
                <TextInput
                  AsteriskStar={true}
                  label={<b>First Name</b>}
                  placeholder={"Enter Name"}
                  value={userFirstName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ userFirstName: inputValue }));
                    } 
                  }}
                />
                <TextInput
                  label={<b>Middle Name</b>}
                  placeholder={"Middle Name"}
                  value={userMiddleName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ userMiddleName: inputValue }));
                    } 
                  }}
                />
                <TextInput
                  AsteriskStar={true}
                  label={<b>Surname</b>}
                  placeholder={"Surname Name"}
                  value={userSurName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[a-zA-Z ]*\.?[a-zA-Z ]*$/
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ userSurName: inputValue }));
                    } 
                  }}
                />
              </div>
              <div className="md:flex mr-2 block mt-2 mb-2 md:justify-between md:gap-[17px]">
                <TextInput
                  AsteriskStar={true}
                  label={<b>Father First Name</b>}
                  placeholder={"Enter Name"}
                  value={fatherFirstName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ fatherFirstName: inputValue }));
                    } 
                  }}
                />
                <TextInput
                  label={<b>Father Middle Name</b>}
                  placeholder={"Middle Name"}
                  value={fatherMiddleName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ fatherMiddleName: inputValue }));
                    } 
                  }}
                />
                <TextInput
                  AsteriskStar={true}
                  label={<b>Father Surname</b>}
                  placeholder={"Enter Surname "}
                  value={fatherSurName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[a-zA-Z ]*\.?[a-zA-Z ]*$/
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ fatherSurName: inputValue }));
                    } 
                  }}
                />
              </div>
              <div className="mt-2 mr-2 w-1/2">
                <TextInput
                  AsteriskStar={true}
                  label={<b>Date of Birth</b>}
                  type={"date"}
                  placeholder={"dd/mm/yyyy"}
                  max={maxDate}
                  min={lastDate}
                  value={dateOfBirth}
                  onChange={(e) => {

                    if(maxDate>=e.target.value){

                    dispatch(formSixtyData({ dateOfBirth:e.target.value }));
                  }}
                }
                />
              </div>
              <label className="font-bold mt-2">Address</label>
              <div className="md:flex block mr-2 mt-2 md:justify-between md:gap-[17px]">
                <TextInput
                  AsteriskStar={true}
                  label={"Flat/Room No."}
                  placeholder={"ex.101"}
                  value={roomNo}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[a-zA-Z0-9\/]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                      dispatch(formSixtyData({ roomNo: e.target.value }));
                    } 
                  }}
                />
                <TextInput
                  label={"Floor No."}
                  placeholder={"ex.11 Floor"}
                  value={floorNo}
                  onChange={(e) => {
                    let value = e.target.value;
                    let floorCodeRegex = /^[A-Za-z0-9\s]+$/;

                    if (floorCodeRegex.test(value) || value === '') {
                      dispatch(formSixtyData({ floorNo: e.target.value }));
                    }
                  }}
                />
                <TextInput
                  label={"Name of Premises."}
                  placeholder={"ex.Vakratunda Place"}
                  value={PremisesName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ PremisesName: inputValue }));
                    } 
                  }}
                />
              </div>
              <div className="md:flex mr-2 block mt-2 md:justify-between md:gap-[17px]">
                <TextInput
                  label={"Block Name/No."}
                  placeholder={"ex.D1"}
                  value={BlockNo}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[a-zA-Z0-9\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                      dispatch(formSixtyData({ BlockNo: e.target.value }));
                    } 
                  }}
                />
                <TextInput
                  AsteriskStar={true}
                  label={"Area/Locality."}
                  placeholder={"ex.Bhandup"}
                  value={area}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ area: inputValue }));
                    } 
                  }}
                />
                <TextInput
                  AsteriskStar={true}
                  label={"Town/City"}
                  placeholder={"Town/City"}
                  value={town}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                    dispatch(formSixtyData({ town: inputValue }));
                    } 
                  }}
                />
              </div>
              <div className="md:flex mr-2 block mt-2 md:justify-between md:gap-[17px]">
                <TextInput
                  AsteriskStar={true}
                  label={"District"}
                  placeholder={"District"}
                  value={district}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[A-Za-z\s]+$/;
                    if (regex.test(inputValue) || inputValue === '') {
                      dispatch(formSixtyData({ district: inputValue }));
                    } 
                    
                  }}
                />
                <div>
                   <FormControl sx={{minWidth:180}} size="small">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        State
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        sx={{fontSize: "0.87rem"}}
                        value={states}
                        onChange={(e) => {
                          dispatch(formSixtyData({ states: e.target.value }));
                        }}
                        placeholder="State"
                        defaultValue={states}
                        className="lato-family"
                        displayEmpty
                        renderValue={states !== "" ? undefined : () => "Select state"}
                      >
                        <MenuItem disabled value="">
                        <em>Select state</em>
                        </MenuItem>
                        {statNames?.map((value, index) => (
                          <MenuItem
                            value={value}
                            key={index + Math.random()}
                            sx={{ width: "auto", fontFamily: "Montserrat" }}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>                
                </div>
                <TextInput
                  AsteriskStar={true}
                  label={"Pin code"}
                  placeholder={"ex:400001"}
                  maxlen={"6"}
                  type="text"
                  value={pinCode}
                  onChange={(e) => {
                    let value = e.target.value;
                    let pinCodeRegex = /^[0-9\b]+$/;
                    if (pinCodeRegex.test(value)) {
                      dispatch(formSixtyData({ pinCode: value }));
                    }
                    if (!pinCodeRegex.test(value)) {
                      dispatch(formSixtyData({ pinCode: "" }));
                    }
                  }}
                />
              </div>
              <div className="md:flex mr-2 mt-4 md:justify-between">
                <TextInput
                  label={<b>Telephone Number (With STD Code)</b>}
                  placeholder={"ex.(0261)2299333"}
                  type="number"
                  width={"md:w-[250px]"}
                  value={telePhoneNumber}
                  onChange={(e) => {
                    let value = e.target.value;
                    const numReg = /^\d{0,11}$/;
                    if (numReg.test(value)) {
                      dispatch(formSixtyData({ telePhoneNumber: value }));
                    }
                  }}
                  stopWheel={true}
                />
                <TextInput
                  AsteriskStar={true}
                  label={<b>Mobile Number</b>}
                  placeholder={"ex.9988776655"}
                  width={"md:w-[250px]"}
                  value={mobileNumber}
                  maxlen={10}
                  onChange={(e) => {
                    let value = e.target.value;
                    const numReg = /^[6-9]\d{0,9}$/;
                    if (numReg.test(value) || value=="") {
                      dispatch(formSixtyData({ mobileNumber: value }));
                    }
                  }}
                />
              </div>
              <div className="md:flex mr-2 block mt-4 mb-2 md:justify-between md:gap-[17px]">
                <TextInput
                  AsteriskStar={true}
                  label={<b>Premium Amount</b>}
                  type="number"
                  placeholder={"ex-Rs10000"}
                  value={premiumAmount}
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 0 || e.target.value=="") {
                      dispatch(formSixtyData({ premiumAmount: e.target.value }));
                    }
                  }}
                  stopWheel={true}
                />
                <TextInput
                  AsteriskStar={true}
                  label={<b>Date of Transaction</b>}
                  type={"date"}
                  placeholder={"dd/mm/yyyy"}
                  value={dateOfTransaction}
                  max={maxDate}
                  onChange={(e) => {
                 
                    if(maxDate>=e.target.value){
                    dispatch(
                      formSixtyData({ dateOfTransaction: e.target.value })
                    );
                  }}}
                
                />
                <Tooltip
                  title="In case of transaction in joint names, number of persons involved in the transaction"
                  arrow
                  placement="top"
                >
                  <div>
                    <TextInput
                      label={<b>No. of Person</b>}
                      placeholder={"ex.3"}
                      type="number"
                      value={numberOfPerson}
                      onChange={(e) => {
                        dispatch(
                          formSixtyData({ numberOfPerson: e.target.value })
                        );
                      }}
                      stopWheel={true}
                    />
                  </div>
                </Tooltip>
              </div>
              <label className="font-bold">
                Mode of Transaction<span style={{ color: "red" }}>*</span>
              </label>
              <div className="flex mr-2 md:gap-[17px]">
                <div>
                  <div>
                    <input
                      className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                      type={"checkbox"}
                      checked={modeOfTransaction === "card"}
                      value="card"
                      onChange={(e) => {
                        modeOfFunc(e);
                      }}
                    />
                    <label
                      htmlFor="link-checkbox"
                      className="ml-2 text-sm font-medium text-black "
                    >
                      Card
                    </label>
                  </div>
                  <div>
                    <input
                      className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                      type={"checkbox"}
                      value="onlineTransfer"
                      checked={modeOfTransaction === "onlineTransfer"}
                      onChange={(e) => {
                        modeOfFunc(e);
                      }}
                    />
                    <label
                      htmlFor="link-checkbox"
                      className="ml-2 text-sm font-medium text-black "
                    >
                      Online Transfer
                    </label>
                  </div>
                </div>
                <div>
                  <div>
                    <input
                      className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                      type={"checkbox"}
                      checked={modeOfTransaction === "cheque"}
                      value="cheque"
                      onChange={(e) => {
                        modeOfFunc(e);
                      }}
                    />
                    <label
                      htmlFor="link-checkbox"
                      className="ml-2 text-sm font-medium text-black "
                    >
                      Cheque
                    </label>
                  </div>
                  <div>
                    <div>
                      <input
                        className="w-5 h-5  rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                        type={"checkbox"}
                        checked={modeOfTransaction === "Draft/BankerCheque"}
                        value="Draft/BankerCheque"
                        onChange={(e) => {
                          modeOfFunc(e);
                        }}
                      />
                      <label
                        htmlFor="link-checkbox"
                        className="ml-2 text-sm font-medium text-black "
                      >
                        Draft/Banker's Cheque
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <Tooltip
                title="Aadhaar Number issued by UIDAI"
                arrow
                placement="top-start"
              >
                <div className="mt-2 mr-2">
                  <TextInput
                    label={<b>Aadhaar Number (if available)</b>}
                    placeholder={"ex: 1111 4444 6666"}
                    value={aadhaarNumber}
                    maxlen={12}
                    onChange={(e)=>{
                      let value = e.target.value;
                      const numReg = /^\d{0,12}$/;
                      if (numReg.test(value)) {
                        dispatch(formSixtyData({ aadhaarNumber: value }));
                      }
                    }}
                  />
                  {aadhaarValidationError && (
                    <p className="text-red-500 text-sm">
                      {aadhaarValidationError}
                    </p>
                  )}
                </div>
              </Tooltip>
              <div
                className="mr-2 mt-2 p-4"
                style={{ border: "1px grey solid", borderStyle: "dashed" }}
              >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // defaultValue="applied"
                >
                  <FormControlLabel
                    value="true"
                    label="Applied for PAN"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                            {
                              color: "#E42125",
                            },
                          "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                            color: "#E42125",
                          },
                        }}
                      />
                    }
                    checked={appliedForPan === "true"}
                    onChange={handlePanStatus}
                  />
                  <FormControlLabel
                    value="false"
                    label="PAN not Applied"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                            {
                              color: "#E42125",
                            },
                          "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                            color: "#E42125",
                          },
                        }}
                      />
                    }
                    onChange={handlePanStatus}
                    checked={appliedForPan === "false"}
                  />
                </RadioGroup>
                <div>
                  <div className="flex mr-2 mt-4 mb-4 md:gap-[17px]">
                    {appliedForPan === "true" ? (
                      <div className="md:flex block mr-2 md:justify-around md:gap-[17px]">
                        <TextInput
                          AsteriskStar={true}
                          type="date"
                          label={"Date of Application"}
                          value={dateOfApplication}
                          onChange={(e) => {
                            dispatch(
                              formSixtyData({
                                dateOfApplication: e.target.value,
                              })
                            );
                          }}
                        />
                        <TextInput
                          AsteriskStar={true}
                          label={"Acknowledgement Number"}
                          value={acknowledgementNumber}
                          placeholder={"ex.101234567893344"}
                          type="number"
                          onChange={(e) => {
                            dispatch(
                              formSixtyData({
                                acknowledgementNumber: e.target.value,
                              })
                            );
                          }}
                          stopWheel={true}
                        />
                      </div>
                    ) : (
                      <div className="md:flex block mr-2 md:justify-around md:gap-[17px]">
                        <TextInput
                          AsteriskStar={otherAgriculturalIncome==""}
                          label={"Agricultural income"}
                          value={agriculturalIncome}
                          type="number"
                          placeholder={"Rs.1,20,770"}
                          onChange={(e) => {
                            if (parseInt(e.target.value) > 0 || e.target.value=="") {
                              dispatch(
                                formSixtyData({
                                  agriculturalIncome: e.target.value,
                                })
                              );
                            }
                          }}
                          stopWheel={true}
                        />
                        <TextInput
                          AsteriskStar={agriculturalIncome==""}
                          label={"Other than agricultural income"}
                          value={otherAgriculturalIncome}
                          type="number"
                          placeholder={"Rs.1,20,770"}
                          onChange={(e) => {
                            if (parseInt(e.target.value) > 0 || e.target.value=="") {
                            dispatch(
                              formSixtyData({
                                otherAgriculturalIncome: e.target.value,
                              })
                            );
                            }
                          }}
                          stopWheel={true}
                        />
                     </div>
                    )}
                  </div>
                </div>
              </div>
              {(aadhaarNumber === "" && appliedForPan === "false") ||
              (aadhaarNumber !== "" && appliedForPan === "false") ||
              !(aadhaarNumber !== "" && appliedForPan === "true") ? (
                <>
                  <div className="md:flex block mt-4 mr-2 mb-4 md:gap-[17px]">
                    <FormControl size="small">
                      <label className="block mb-2 text-sm font-medium text-gray-900">
                        Select Your Document
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        sx={{fontSize: "0.87rem"}}
                        displayEmpty
                        value={otherDocsDropDownValue}
                        onChange={(e) => {
                          dispatch(optionsValue(e.target.value));
                          dispatch(
                            formSixtyData({
                              selectedDoc: e.target.value,
                            })
                          );
                          setSelectedDocument(e.target.value);
                          setDocumentCode(e.target.value);
                        }}
                        defaultValue={selectedDocument}
                        className="lato-family"
                      >
                        {[
                          "aadhaar",
                          "maskedAadhaar",
                          "drivingLicence",
                          "passport",
                          "voterid",
                        ]?.map((value, index) => (
                          <MenuItem
                            value={value}
                            key={index + Math.random()}
                            sx={{ width: "auto", fontFamily: "Montserrat" }}
                          >
                            {capitalizeFirstLetter(value)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl  size="small">
                    <TextInput
                      AsteriskStar={true}
                      label={
                        "Document identification number"
                      }
                      value={documentIdentificationNumber}
                      placeholder={capitalizeFirstLetter(selectedDocument)}
                      onChange={(e) => {
                        if(documentCode == "01"){
                          dispatch(formSixtyData({ aadhaarNumber: e.target.value }));
                        }
                        dispatch(
                          formSixtyData({ documentIdentificationNumber:e.target.value})
                        );
                      }}
                    />
                    </FormControl>
                  </div>
                  {/* <div className="flex mr-2 gap-[17px]">
                    <TextInput
                      AsteriskStar={true}
                      label={
                        "Name and address of the authority issuing the document"
                      }
                      value={authorityName}
                      placeholder={"Name,address"}
                      onChange={(e) => {
                        dispatch(
                          formSixtyData({ authorityName: e.target.value })
                        );
                      }}
                    />
                  </div> */}
                </>
              ) : (
                ""
              )}
              <div className="mt-4 mr-2 w-1/2">
                <TextInput
                  AsteriskStar={true}
                  label={"Place Where Signed"}
                  value={placeWhereSigned}
                  placeholder={"ex.Mumbai"}
                  onChange={(e) => {
                    const result=e.target.value.replace(/[^a-z]/gi,"");
                    dispatch(
                      formSixtyData({ placeWhereSigned: result })
                    );
                  }}
                />
              </div>
              {signatureName !== "" ? (
                <div className="flex mr-2 border justify-between  p-4 mt-4">
                  {signatureName}
                  <div className="flex justify-end mr-4">
                    <VisibilityIcon
                      className="mr-4"
                      onClick={openSignatureImageHandler}
                    />
                    {signaturepreview && (
                      <PreviewDocument
                        imgUrl={signatureFile}
                        closePreview={closePreview}
                      />
                    )}
                    <DeleteIcon
                      onClick={() => {
                        dispatch(
                          formSixtyData({
                            signatureName: "",
                            signatureFile: "",
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="border-2 mr-2 border-dashed flex justify-between items-center p-4 mt-4">
                  <FileUploadIcon />
                  <div>
                    <label className="font-bold">
                      Signature<span style={{ color: "red" }}>*</span>
                    </label>
                    <p className="opacity-80">
                      Support file formats are png,jpeg,webp
                    </p>
                  </div>
                  <div className="w-[150px]">
                    <ImageCrop></ImageCrop>
                  </div>
                </div>
              )}
              <div className="w-full mr-2 md:w-[300px] flex md:pb-1 mt-[10px]">
                <CommonButton
                  label="Submit"
                  filled
                  onClickHandler={()=>onSubmitHandler()}
                />
              </div>
            </div>
            <Dialog
                open={confirmSubmitDialog}
                onClose={() => {
                  setConfirmSubmitDialog(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Do you wish to proceed with the final submission ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setConfirmSubmitDialog(false)}
                    color="error"
                    id="alert-ovd-no-button"
                  >
                    No
                  </Button>
                  <Button
                    onClick={async () => {
                      setIsLoading(true);
                      // onSubmitHandler();
                      setConfirmSubmitDialog(false);
                      dispatch(toggleTabs("otherDocs"));
                      dispatch(toggleButton(false));
                      try{
                        let response = await form60Upload(digitalFormSixtyData);
                        if (response?.success) {
                          dispatch(formSixtyData({digitalForm60Id: response?.data?.form60Url?.digitalForm60Id })); 
                          dispatch(formSixtyData({formSixtyString: response?.data?.form60Url?.base64String }));
                          if(methodOfKyc==="corporateProprietor") return navigate("/documentUpload", { state: { isFormSixtyFilled: true,isRouteAllowed: true,  } });
                          navigate("/kyc", { state: { isFormSixtyFilled: true ,otherdocs:true} });
                        }
                      }catch(e){
                        console.log(e);
                        setIsLoading(false);
                      }
                    }}
                    color="success"
                    autoFocus
                    id="alert-ovd-yes-button"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
          </BottomCard>
        </div>
      </MsHome>
    </React.Fragment>
  )}
  </>
  );
};

export default Digitalformsixty;
