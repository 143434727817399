import { TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import CommonDropDown from "../../Components/global-components/CommonDropDown/CommonDropDown";
import TextInput from "../../Components/global-components/CommonTextInput/TextInput";
import Tab from "../../Components/kyc-components/Tab";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { gaFunction, scrollIntoViewFunc } from "../../Router";
import { marutiUrl } from "../../services/maruti-service";
import { activeStepper } from "../Redux/stepperSlice";
import styles from "./HeadlessDueDeligence.module.css";
import { encrypt,decrypt } from "../../utils/encryptionV2";


const options = {
  option1: [
    "Select",
    "0-2.5 lacs",
    "2.5-5 lacs",
    "5-15 lacs",
    "15-20 lacs",
    "20-30 lacs",
    "30+ lacs",
  ],
  option2: [
    "Select",
    "Salaried",
    "Professional",
    "Self Employeed",
    "Student",
    "Home maker",
    "Other",
  ],
  option3: [
    "Select",
    "Government",
    "Private Limited",
    "Public Limited",
    "Trust",
    "Other",
  ],
  option4: ["Select", "Yes", "No", "Related to one"],
};
const gender = ["Gender", "Male", "Female", "Others"];
const maritalStatus = ["Marital Status", "Single", "Married"];
const HeadlessDueDeligence = () => {
  const [annualIncome, setAnnaulIncome] = useState("");
  const [error, setError] = useState("");
  const [occupation, setOccupation] = useState("");
  const [organization, setOrganization] = useState("");
  const [political, setPolitical] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [maritalValue, setMaritalValue] = useState("");
  const [motherName, setMotherName] = useState("");
  const [occupationOther, setOccupationOther] = useState("");
  const [organizationOther, setOrganizationOther] = useState("");
  let { phoneNumber } = useSelector((state) => state.userDetails.userData);
  const userPanNumber = useSelector((state) => state.panDetails.userPanNumber);
  const { mi_u, channelUrl,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );

  const { state } = useLocation();
  const { isRouteAllowed, type } = state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // remove on production
  if (Object.keys(phoneNumber).length === 0)
    phoneNumber = localStorage.getItem("mobileNumber");

  // const backHandler = () => {
  //   navigate("/panDetails");
  // };

  const onClickHandler = async () => {
    if (
      annualIncome !== "" &&
      occupation !== "" &&
      organization !== "" &&
      political !== "" &&
      motherName !== "" &&
      genderValue !== "" &&
      maritalValue !== ""
    ) {
      if (
        (occupation === "Other" && !occupationOther) ||
        (organization === "Other" && !organizationOther)
      ) {
        setError("Please fill up the given form");
        scrollIntoViewFunc("incomeError");
        return;
      }
      gaFunction("maruti", "maruti only due deligence income submitted");
      let data = JSON.stringify({
        // mobile: phoneNumber,
        pan: userPanNumber,
        annualIncome: annualIncome,
        occupation:
          occupation === "Other"
            ? `${occupation} - ${occupationOther}`
            : occupation,
        organizationType:
          organization === "Other"
            ? `${organization} - ${organizationOther}`
            : organization,
        isPoliticallyExposed:
          political === "Yes" || political === "Related to one" ? true : false,
        gender: genderValue,
        maritalStatus: maritalValue,
        // mi_identifier: mi_u,
        channel: channelUrl,
        txnId: mi_u,
        subChannel,
        // mothersName: mothersName,
        // correspondenceAddress: correspondenceAddress,
        // correspondenceState: correspondenceState,
        // correspondenceCity: correspondenceCity,
        // correspondencePincode: correspondencePincode,
        type: "pan",
      });
      let dataLogin = JSON.stringify({
        mobile: "1111111111",
      });
      await marutiUrl(dataLogin)
        .then(function (data) {
          if (data.success === true) {
            localStorage.setItem("token", data.data.token);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // https://ctrln-uat.hdfcergo.com
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}/customer/customer-kyc`,
        headers: {
          "Content-Type": "application/json",
          // token: localStorage.getItem("token"),
        },
        // data: data,
        data: {enc_request: encrypt(data)},
      };
      axios(config)
        // .then(function (response) {
        //   if (response.data.success === true) {
        //     navigate("/Success", {
        //       state: {
        //         kyc_ref_id: response?.data?.data?.kyc_id,
        //         successUrl: response?.data?.data?.success_url,
        //       },
        //     });
        //   }
        // })
        .then(function (response) {
        let decResponse= decrypt(response?.data?.enc_response)
        console.log("HEAD LESS DUE FETCH DETAIL RESPONSE --",decResponse);
          if (decResponse.success === true) {
            navigate("/Success", {
              state: {
                kyc_ref_id: decResponse.data?.kyc_id,
                successUrl: decResponse.data?.success_url,
              },
            });
          }
        })
        .catch(function (error) {
        console.log("HEAD LESS DUE FETCH DETAIL FAILED --",error);
        });
    } else {
      setError("Please fill up the given form");
      scrollIntoViewFunc("incomeError");
    }
  };

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);

  useEffect(() => {
    if (!channelUrl) return navigate("/*");
  }, [navigate, channelUrl]);

  useEffect(() => {
    gaFunction("maruti", "due deligence loaded");
  }, []);
  return (
    <>
      <div className={styles.AnnualIncome}>
        <MsHome>
          <BannerImage>
            {/* <MdKeyboardBackspace
                    size={30}
                    style={{ margin: 20 }}
                    onClick={backHandler}
                  /> */}
            <LogoContainer />
          </BannerImage>
          <BottomCard max stepper={false}>
            <div className="hidden md:block md:h-[14%]">
              <div className="p-[10px] md:p-0"></div>
              <label className="md:text-xl">
                <b>KYC Document</b>
              </label>
              <p className="hidden md:block md:text-sm">
                let's fetch your kyc details using
              </p>
              <div className="p-[10px]"></div>
              <div className="md:w-[50%] md:mb-1">
                {/* <Tab isDisabled /> */}
              </div>
            </div>
            <div className="md:w-[60%]  md:h-[86%] md:mt-2">
              <div className="md:h-[77%] overflow-scroll pl-2 md:p-0">
                <TextInput
                  placeholder="Mother maiden name"
                  id="mother_name"
                  value={motherName}
                  onChange={(e) => setMotherName(e.target.value)}
                />
                <div className="mb-2" />
                <CommonDropDown
                  options={gender}
                  selected={genderValue}
                  setSelected={setGenderValue}
                />
                <CommonDropDown
                  options={maritalStatus}
                  selected={maritalValue}
                  setSelected={setMaritalValue}
                />
                <CommonDropDown
                  options={options.option1}
                  selected={annualIncome}
                  setSelected={setAnnaulIncome}
                  label={"Annual Income"}
                />
                <CommonDropDown
                  options={options.option2}
                  selected={occupation}
                  setSelected={setOccupation}
                  label={"Occupation"}
                />
                {occupation === "Other" && (
                  <TextField
                    fullWidth
                    size="small"
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    placeholder="Please specify other"
                    onChange={(e) => setOccupationOther(e.target.value)}
                  />
                )}
                <CommonDropDown
                  options={options.option3}
                  selected={organization}
                  setSelected={setOrganization}
                  label={"Organization Type"}
                />
                {organization === "Other" && (
                  <TextField
                    fullWidth
                    size="small"
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    placeholder="Please specify other"
                    onChange={(e) => setOrganizationOther(e.target.value)}
                  />
                )}
                <CommonDropDown
                  options={options.option4}
                  selected={political}
                  setSelected={setPolitical}
                  label={"Are you a Politically Exposed Person(PEP) or family member/ close relative / associate of PEP"}
                />
                <p className="text-red-500 text-sm" id="incomeError">
                  {error && error}
                </p>
                <i className="text-xs font-medium text-black">
                      "Politically Exposed Persons" (PEPs) are individuals who have been entrusted with prominent public functions by a foreign country, including the heads of States or Governments, senior politicians, senior government or judicial or military officers, senior executives of state-owned corporations and important political party officials.
                </i>
                {/* <div className="h-1/3"></div> */}
              </div>
              <div className="w-full mt-7 md:w-[100%] md:mt-2 mb-3">
                <CommonButton
                  label="Proceed"
                  filled
                  arrow
                  id={"incomeProcess"}
                  onClickHandler={onClickHandler}
                />
              </div>
            </div>
          </BottomCard>
        </MsHome>
      </div>
    </>
  );
};

export default HeadlessDueDeligence;
