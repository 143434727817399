import React from "react";
import PropTypes from "prop-types";
import Steppers from "../../kyc-components/Steppers";
import { useSelector } from "react-redux";

function BottomCard({ max, children, stepper = true }) {
  let conditionalHeightStyle = max ? " h-max" : " h-full";
  const activeSwitch = useSelector((state) => state.switchButton.activeSwitch);

  return (
    <div
      className={
        " md:relative flex md:rounded-none md:shadow-none   flex-col w-full p-4 md:p-[16px_18px_0px_16px] min-h-[50vh] pt-[26px] bg-white rounded-t-[30px] shadow-[0px_-4px_16px_-15px_rgba(0,0,0,0.75)] md:flex md:flex-col md:h-[100%] md:overflow-hidden" +
        conditionalHeightStyle
      }
    >
      {/* STEPPER */}
      <div className=" md:w-[80%] md:flex-[0_0_auto]">
        {!activeSwitch ? "" : <Steppers visibility={stepper}></Steppers>}
      </div>
      <div className="md:flex-[1_0_0] md:overflow-hidden md:flex md:flex-col">
        {children}
      </div>
      <div className="text-center mt-2 md:mt-2 visible md:hidden  md:absolute md:left-0 md:right-0  md:bottom-4">
        <p>
          <span className="opacity-50">Powered by</span> &nbsp;
          <span className="drop-shadow-md bg-white pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm font-bold">
            Ctrl
          </span>
          &nbsp;
          <span className="drop-shadow-md bg-red-500 pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm text-white font-bold">
            N
          </span>
        </p>
      </div>
    </div>
  );
}

export default BottomCard;

BottomCard.propTypes = {
  max: PropTypes.bool,
  stepper: PropTypes.bool,
};
