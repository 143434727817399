import React from "react";
import { useSelector } from "react-redux";
// import Footer from "../global-components/FooterComponent/Footer";
import HiddenWrapper from "../global-components/hiddenWrapper/HiddenWrapper";
import MsFooter from "./MsFooter";
import MsHeader from "./MsHeader";
import MsRightSideImg from "./MsRightSideImg";

// const MsHome = ({ children,newLogin }) => {
const MsHome = ({ children }) => {
  const isDocumentPageRender = useSelector(
    (state) => state.corporateSlice.isDocumentPageRender
  );
  return (
    <div className="w-full h-full md:flex md:flex-col md:flex-[1_0_0]">
      <HiddenWrapper>
        <MsHeader />
      </HiddenWrapper>
      <div className="md:flex md:justify-around md:p-6 md:flex-[1_0_0] md:overflow-hidden">
        <div className="md:flex md:flex-col md:w-[62%] md:min-w-[62%]">
          <HiddenWrapper>
            {/* {!newLogin && <div className="md:ml-4"> */}
           <div className="md:ml-4">
              <p className="text-[#113569] font-bold text-base mb-1">
                KYC has been made easier!
              </p>
              {isDocumentPageRender ? (
                <label className="text-sm">Authorised Signatory</label>
              ) : ( ''
                // <label className="text-sm">
                //   Kindly share your CKYC details
                // </label>
              )}
              {/* <p className="text-[#113569] text-sm font-medium">
                Choose your digital-friendly options.
              </p> */}
              {/* <p className="opacity-50 w-[90%] text-sm">
                By verifying ID cards and faces, confirming addresses by utility
                bills , KYC secures customers’ accounts.
              </p> */}
            </div>
          </HiddenWrapper>
          <div className=" md:w-[80%] md:flex-[1_0_0] md:overflow-hidden">
            {children}
          </div>
        </div>
        <HiddenWrapper width="true">
          <MsRightSideImg />
        </HiddenWrapper>
      </div>
      <HiddenWrapper>
        <div className=" bottom-0 w-full md:flex-initial">
          <MsFooter />
        </div>
      </HiddenWrapper>
    </div>
  );
};

export default MsHome;
