import { createSlice } from "@reduxjs/toolkit";

export const partnerDigiJourneyFlagSlice = createSlice({
  name: "partnerDigiJourneyFlag",

  initialState: {
    value: false
  },
  reducers: {
    updatePartnerDigiJourneyFlag: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {
  updatePartnerDigiJourneyFlag
} = partnerDigiJourneyFlagSlice.actions;
export default partnerDigiJourneyFlagSlice.reducer;