import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedValue: "",
  sendThisValueToBackend: "",
  kycType: "",
  errorMessage: "",
  properietorName: "",
  anotherMode: "",
  userPanNumber: "",
  ckycNumber: "",
  cinNumber: "",
  gstNumber:"",
  radioValue: "",
  dateOfIncorporation: "",
  proprietorRadioValue: "",
  trustRadioValue: "",
  individualPanNumber: "",
  userData: {
    name: "",
    permanentAddress: "",
    doi: "",
    permanentCity: "",
    permanentState: "",
    permanentPincode: "",
    correspondenceAddressLineOne: "",
    correspondenceAddressLineTwo: "",
    correspondenceState: "",
    correspondenceCity: "",
    correspondencePincode: "",
  },
  panUserdata: {},
  allDocumentName: {},
  kycId: "",
  addNewKycId: "",
  isDocumentPageRender: false,
  companyType:""
}
export const corporateSlice = createSlice({
  name: "corporateValue",
  initialState,
  reducers: {
    corporateDropDownValue: (state, action) => {
      state.selectedValue = action.payload;
    },
    corporateProperietorName: (state, action) => {
      state.properietorName = action.payload;
    },
    corporateAnotherMode: (state, action) => {
      state.anotherMode = action.payload;
    },
    corporateBackendValue: (state, action) => {
      state.sendThisValueToBackend = action.payload
    },
    corporateCompanyType: (state, action) => {
      state.companyType = action.payload
    },
    coProprietorRadioValue: (state, action) => {
      state.proprietorRadioValue = action.payload
    },
    coTrustRadioValue: (state, action) => {
      state.trustRadioValue = action.payload
    },
    coCkycNumber: (state, action) => {
      state.ckycNumber = action.payload
    },
    coRadioValue: (state, action) => {
      state.radioValue = action.payload
    },
    coCinNumber: (state, action) => {
      state.cinNumber = action.payload
    },
    coGstNumber: (state, action) => {
      state.gstNumber = action.payload
    },
    coIndividualPanNumber: (state, action) => {
      state.individualPanNumber = action.payload
    },
    kycType: (state, action) => {
      state.kycType = action.payload
    },
    coFileUpload: (state, action) => {
      state.allDocumentName = { ...state.allDocumentName, ...action.payload };
    },
    coErrorMessageReducer: (state, action) => {
      state.errorMessage = action.payload
    },
    deleteAllDocument: (state, action) => {
      state.allDocumentName = {}
    },
    clearState: (state) => {
      return { ...initialState, preloadData: { ...state.preloadData } }
    },
    coUserDataReducer: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    coPanUserdataReducer: (state, action) => {
      state.panUserdata = action.payload;
    },
    coUserPanNumberReducer: (state, action) => {
      state.userPanNumber = action.payload;
    },
    coUserDoiReducer: (state, action) => {
      state.dateOfIncorporation = action.payload;
    },
    cokycIdReducer: (state, action) => {
      state.kycId = action.payload;
    },
    coAddNewKycIdReducer: (state, action) => {
      state.addNewKycId = action.payload
    },

    coIsDocumentPageRender: (state, action) => {
      state.isDocumentPageRender = action.payload
    },
  }
})

export const { corporateDropDownValue, coFileUpload, corporateBackendValue, kycType, deleteAllDocument, coErrorMessageReducer, corporateProperietorName, corporateAnotherMode, coUserDataReducer, coPanUserdataReducer, coUserPanNumberReducer, cokycIdReducer, coAddNewKycIdReducer, coUserDoiReducer, clearState, coIsDocumentPageRender, coProprietorRadioValue, coTrustRadioValue, coCkycNumber, coCinNumber, coGstNumber, coRadioValue, coIndividualPanNumber, corporateCompanyType } = corporateSlice.actions
export default corporateSlice.reducer
