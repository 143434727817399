import { apiInstance } from "./networking";

export const coPanService = async (payload) => {
  const { data = {} } = await apiInstance.post(`primary/corp-ckyc-details`, payload);

  return data;
};

export const coCinService = async (payload) => {
  const {data = {}} = await apiInstance.post(`primary/cin-details`,payload)
  return data
}

export const coGstService = async (payload) => {
  console.log("payload for api call",payload)
  const {data = {}} = await apiInstance.post(`primary/v2/gst-details`,payload)
  return data
}
