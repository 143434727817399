import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { gaFunction } from "../../Router";
import { getBinaryUserDataPanDob } from "../../services/vendorServices/binary-service";
import { storeToken } from "../Redux/authSlice";
import { coPreloadData } from "../Redux/corporatePreloadData";
import { showGlobalError } from "../Redux/globalErrorSlice";
import { documentDetail } from "../Redux/otherDocsSlice";
import { panUserdata } from "../Redux/panCardSlice";
import { updateMarutiUserDetails } from "../Redux/partnerChannelNameSlice";
import { customerData, updateUserData } from "../Redux/userData";
import { corporateDropDownValue ,corporateCompanyType} from "../Redux/corporateSlice";
import { updatePartnerDigiJourneyFlag } from "../Redux/partnerDigiJourneyFlagSlice";
import axios from "axios";

const VerifiedPartner = () => {
  const { token } = useSelector((state) => state.authSlice);
  const { customerType ,mi_u, channelUrl} = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetDB = useCallback(() => {
    dispatch(panUserdata({}));
    dispatch(documentDetail({ dob: "", fatherName: "" }))
    dispatch(customerData({ fatherName: "" }))
    dispatch(updateUserData({ phoneNumber: "" }))
    dispatch(corporateCompanyType(""))
    dispatch(corporateDropDownValue(""));
  }, [dispatch])

  useEffect(() => {
    //binary login
    if (window.location.pathname === "/verified-partner") {
      let channelName;
      let partnerDigiJourneyFlag;
      let mTxn = "";
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      channelName = urlParams.get("channel");
      partnerDigiJourneyFlag = urlParams.get("partnerDigiJourney");
      dispatch(updatePartnerDigiJourneyFlag(partnerDigiJourneyFlag));
      gaFunction(`${channelName}`, `${channelName} logged in`);
      mTxn = urlParams.get("txnId");
      let subChannel = urlParams.get("subChannel");
      console.log("subChannel",subChannel)
      updateUserData({
        panNumber: "",
        dob: "",
      });
      resetDB()
      dispatch(
        updateMarutiUserDetails({
          channelUrl: channelName,
          mi_u: mTxn,
          customerType: urlParams.get("customerType") || "I",
          subChannel:subChannel,
        })
      );
      console.log("uelredirect",urlParams.get("redirectUrl"))
      dispatch(storeToken(urlParams.get("token")));
      localStorage.setItem("txnId", urlParams.get("txnId"));
      localStorage.setItem("token", urlParams.get("token"));
      localStorage.setItem(
        "redirectUrl",
        decodeURIComponent(urlParams.get("redirectUrl"))
      );
      localStorage.setItem("channel", urlParams.get("channel"));
      localStorage.setItem(
        "callbackUrl",
        decodeURIComponent(urlParams.get("callbackUrl"))
      );
      if (channelName !== null || channelName?.length > 0) {
        if (channelName === "binary" && urlParams.get("customerType") === "C") {
          getBinaryUserDataPanDob({
            txnId: mTxn || "",
            channelUrl: channelName || "",
          })
            .then((response) => {
              if (response?.success === true) {
                if (Object.keys(response?.data)?.length > 1) {
                  dispatch(
                    coPreloadData({
                      pan: response?.data?.pan || "",
                      doi: response?.data?.dob
                        ?.split("/")
                        ?.join("-"),
                      cin: response?.data?.cinnumber || "",
                      ckyc: response?.data?.ckycnumber || "",
                    })
                  );
                }
              }
            })
            .catch((err) => {
              dispatch(
                showGlobalError("Something went wrong. Please try again")
              );
              navigate("/*");
            });
        } else {
          coPreloadData({
            pan: "",
            doi: "",
            cin: "",
            ckyc: "",
          })
        }
        // navigate("/kyc");
      }
    }
  }, [dispatch, resetDB]);

  useEffect(() => {
    if (token && customerType === "I") {
      navigate("/kyc", { replace: true });
    }else if(channelUrl==="maruti" && customerType === "C"){
        let checkMarutiStatusData = {
          method: "get",
          url: `${process.env.REACT_APP_BASE_URL}/partner/kyc-status/transaction-id/${mi_u}`,
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        };
  
        axios(checkMarutiStatusData)
          .then(function (response) {
            console.log("Response",response)
            if (response.data.data.status === "approved" || response.data.data.status === "pending for verification") {
              console.log("Response we grt we user visit again",response.data.data);
              navigate("/Success", {
                state: { kyc_ref_id: response?.data?.data?.kyc_id },
              });
            }else{
              setTimeout(() => { navigate("/corporatePage", { replace: true }) }, 500)
            }
          })
          .catch(function (error) {
            setTimeout(() => { navigate("/corporatePage", { replace: true }) }, 500)
            console.log(error);
          });
    
    }else if (token && customerType === "C") {
      setTimeout(() => { navigate("/corporatePage", { replace: true }) }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, customerType]);
  return <></>;
};

export default VerifiedPartner;
