import React, { useState, useEffect, useCallback } from "react";
import BottomCard from "../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../Components/login-components/logo-container/LogoContainer";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Row from "../../Components/global-components/row/row";
import OtpInput from "react-otp-input";
import styles from "./OtpScreen.module.css";
import CommonButton from "../../Components/global-components/CommonButton/CommonButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BannerImage from "../../Components/login-components/banner-image/BannerImage";
import { getOtp,getOtpV2, verifyOtp,verifyOtpV2 } from "../../services/OTP-service";
import { storeToken } from "../Redux/authSlice";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { gaFunction } from "../../Router";
import { encrypt,decrypt } from "../../utils/encryptionV2";
// import { subscribe, isSupported } from "on-screen-keyboard-detector";
function OtpPage() {
  const [otpValue, setOtpValue] = useState("");
  const [timer, setTimer] = useState(30);
  const [resendOtpCount, setResendOtpCount] = useState(1);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  // const [errorIsShown, setErrorIsShown] = useState(false);
  const navigate = useNavigate();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const userDetails = useSelector((state) => state.userDetails.userData);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  console.log("testing")

  useEffect(() => {
    let resendOTPTimer;
    if (timer > 0) {
      resendOTPTimer = setTimeout(timeOutCallback, 1000);
    }

    return () => {
      clearTimeout(resendOTPTimer);
    };
  }, [timer, timeOutCallback]);

  const homeHandler = () => {
    gaFunction("verify otp", "verify otp clicked");
    if (!userDetails.phoneNumber) {
      navigate("/login")
      return;
    }
    let data = JSON.stringify({
      mobile: userDetails.phoneNumber,
      otp: otpValue,
      name: userDetails.name,
    });
    let encryptedData=encrypt(data)
    setIsButtonLoading(pre=> !pre)
    verifyOtpV2(encryptedData)
      .then(function (data) {
        let decryptedData =decrypt(data.enc_response)
        if (decryptedData.success === true) {
          setIsButtonLoading(pre=> !pre);
          localStorage.setItem("token", decryptedData.data.token);
          dispatch(storeToken(decryptedData.data.token));
          navigate("/home");
        } else {
          setIsButtonLoading(pre=> !pre);
          setError("Invalid OTP");
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      })
      .catch(function (error) {
        setIsButtonLoading(pre=> !pre);
        setError("Error in verifying OTP, please try again");
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };
  //resend OTP Handler
  const resendHandler = () => {
    if (!userDetails.phoneNumber) {
      setError("Please provide mobile number");
      return;
    }
    setTimer(30);
    setResendOtpCount((prev) => prev + 1);
    let data = JSON.stringify({
      mobile: userDetails.phoneNumber,
      length: 6,
      recurring: true,
      use_type: "login",
      template: 2,
    });
    let encryptedData=encrypt(data)
    getOtpV2(encryptedData)
      .then(function (data) {
        let decryptedData =decrypt(data.enc_response)
        if (decryptedData.success === false) {
          setError("Invalid OTP");
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      })
      .catch(function (error) {
        if (
          error?.response?.decryptedData?.message ===
          "Multiple get OTP attempts. Please try after sometime"
        ) {
          navigate("/login")
        }
        // setError("Error in sending OTP, please try again");
        // setTimeout(() => {
        //   setError("");
        // }, 3000);
      });
  };

  const iconClickHandler = () => {
    navigate("/login")
  };

  // const navigateHandler = () => {
  //   setTimeout(() => {
  //     navigate("/login")
  //   }, 3000);
  // };

  return (
    <div className="flex flex-col h-full">
      <MsHome>
        <BannerImage>
          <LogoContainer />
        </BannerImage>
        <div className="bg-secondaryH h-full">
          <BottomCard stepper={false}>
            <div className="md:w-[60%]">
              <label>
                <b>Login</b>
              </label>
              <div className={styles.spacer}></div>
              <div className="h-full ">
                <p>Please enter the OTP recevied</p>
                <div className={styles.smallSpacer}></div>
                <Row>
                  <p>+91-{userDetails.phoneNumber}</p>
                  <MdOutlineModeEditOutline onClick={iconClickHandler} />
                </Row>
                <div className={styles.smallSpacer}></div>
                <label>
                  <b>OTP</b>
                </label>
                <div className={styles.smallSpacer}></div>
                <OtpInput
                  autofocus
                  numInputs={6}
                  value={otpValue}
                  isInputNum={true}
                  inputStyle={`${styles["otp-input"]} focus:ring-0 `}
                  onChange={(otpValue) => setOtpValue(otpValue)}
                  focusStyle={styles["focus-style"]}
                  separator={<span>&nbsp;&nbsp;</span>}
                  shouldAutoFocus
                />
                <div className={styles.smallSpacer}></div>
                {error && <div className="text-red-500">{error}</div>}
                {timer === 0 ? (
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={resendHandler}
                  >
                    Resend OTP
                  </span>
                ) : (
                  <span> {`Resend OTP in ${timer} seconds`}</span>
                )}

                <footer className="w-full mt-12 md:w-[235px]">
                  <CommonButton
                    label="Verify"
                    filled
                    center
                    loading={isButtonLoading}
                    onClickHandler={homeHandler}
                  />
                </footer>
              </div>
            </div>
          </BottomCard>
        </div>
      </MsHome>
    </div>
  );
}


export default OtpPage;

//  <div className={styles["otp-form-container"]}>
