import React, { useEffect, useState } from "react";
import BannerImage from "../../../Components/login-components/banner-image/BannerImage";
import BottomCard from "../../../Components/login-components/bottom-card/BottomCard";
import LogoContainer from "../../../Components/login-components/logo-container/LogoContainer";
import { BiUser } from "react-icons/bi";
import { MdOutlineLocationOn } from "react-icons/md";
import CommonButton from "../../../Components/global-components/CommonButton/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDrawer from "../../../Components/global-components/CommonDrawer/CommonDrawer";
import { useDispatch, useSelector } from "react-redux";
import Tab from "../../../Components/kyc-components/Tab";
// import { MdOutlineModeEditOutline } from "react-icons/md";
import TextInput from "../../../Components/global-components/CommonTextInput/TextInput";
import CommonDropDown from "../../../Components/global-components/CommonDropDown/CommonDropDown";
import ErrorBottomDrawer from "../../../Components/global-components/ErrorBottomDrawer/ErrorBottomDrawer";
import { customerData } from "../../Redux/userData";
import MsHome from "../../../Components/Maruti-Suzuki-components/MsHome";
import { activeStepper } from "../../Redux/stepperSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { formValidator, gaFunction, OEM_List } from "../../../Router";
import { showGlobalError } from "../../Redux/globalErrorSlice";
import axios from "axios";
import { encrypt,decrypt } from "../../../utils/encryptionV2";

const gender = ["Gender", "Male", "Female", "Others"];
const maritalStatus = ["Marital Status", "Single", "Married"];

const PanCardFetchDetailsFrame = () => {
  const panUserDetails = useSelector((state) => state.panDetails.panUserdata);
  let {
    permanentAddress,
    permanentCity,
    permanentDistrict,
    permanentCountry,
    permanentState,
    permanentPincode,
  } = useSelector((state) => state.userDetails.customerData);
  const userPanNumber = useSelector((state) => state.panDetails.userPanNumber);
  const { mi_u, channelUrl,subChannel } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  const kycId = useSelector((state) => state.kycDetails.kycRefIdAddNewKyc);
  const { dob:dobOVD } = useSelector(
    (state) => state.uploadedFile.previewDocumentDetail
  );
  const switchGendervalue = (panUserDetails) => {
    switch (panUserDetails) {
      case "M":
        return "Male";

      case "F":
        return "Female";

      case "O":
        return "Others";

      case "T":
        return "Others";

      default:
        return "";
    }
  };

  const [genderValue, setGenderValue] = useState(
    switchGendervalue(panUserDetails?.gender) || ""
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [errorMessageDrawer, setErrorMessageDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [addressActive, setAddressActive] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState(true);
  const [showBottomButtons, setShowBottomButtons] = useState(true);
  const [motherName, setMotherName] = useState("");
  const [coAddress, setCoAddress] = useState("");
  const [coState, setCoState] = useState("");
  const [coCity, setCoCity] = useState("");
  const [coPinCode, setCoPinCode] = useState("");
  const [error, setError] = useState("");
  const [coError, setCoError] = useState("");
  const [maritalValue, setMaritalValue] = useState("");
  const [IsCheckbox, setIsCheckbox] = useState(false);
  const [fatherName, setfatherName] = useState(
    panUserDetails?.fatherName || ""
  );

    // ARYA start
    const [perAddress, setPerAddress] = useState(panUserDetails?.permanentAddress || "");
    const [perState, setPerState] = useState(panUserDetails?.permanentState || "");
    const [perCity, setPerCity] = useState(panUserDetails?.permanentCity || "");
    const [perPinCode, setPerPinCode] = useState(panUserDetails?.permanentPincode || "");
    const [isEdit, setIsEdit] = useState(false);
    // ARYA end

  const { state } = useLocation();
  const { isRouteAllowed } = state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let fieldArraySINZY = [
    { fieldLength: motherName?.trim()?.length, fieldName: "mother name" },
    { fieldLength: fatherName?.trim()?.length, fieldName: "father name" },
    { fieldLength: maritalValue?.trim()?.length, fieldName: "marital status" },
    { fieldLength: genderValue?.trim()?.length, fieldName: "gender" },
  ];
  let fieldArrayBOTH = [
    { fieldLength: motherName?.trim()?.length, fieldName: "mother name" },
    { fieldLength: fatherName?.trim()?.length, fieldName: "father name" },
    { fieldLength: maritalValue?.trim()?.length, fieldName: "marital status" },
    { fieldLength: genderValue?.trim()?.length, fieldName: "gender" },
    { fieldLength: perAddress?.trim()?.length, fieldName: "Permanent Address" },
    { fieldLength: perState?.trim()?.length, fieldName: "Permanent State" },
    { fieldLength: perCity?.trim()?.length, fieldName: "Permanent City" },
    { fieldLength: perPinCode?.trim()?.length, fieldName: "Permanent Pincode" },
  ];

  let correspondenceFieldArray = [
    {
      fieldLength: coAddress?.trim()?.length,
      fieldName: "Correspondence Address",
    },
    { fieldLength: coState?.trim()?.length, fieldName: "Correspondence State" },
    { fieldLength: coCity?.trim()?.length, fieldName: "Correspondence City" },
    {
      fieldLength: coPinCode?.trim()?.length,
      fieldName: "Correspondence Pincode",
    },
  ];

  const pinCodeValidator = (value , type) => {
    let pinCodeRegex = /^[0-9\b]+$/;
    if (pinCodeRegex.test(value)) {

     type === "co"?  setCoPinCode(value) : setPerPinCode(value);
    }
    if (!pinCodeRegex.test(value)) {
      type === "co"?  setCoPinCode("") : setPerPinCode("");
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setOpenDrawer(true);
    }, 1000);
    if (!state) navigate("/login")
  }, [navigate, state]);

  // const backHandler = () => {
  //   navigate("/kyc");
  // };

  const skipDueDeligence = () => {

    console.log("inskip due delligence")
    let data = JSON.stringify({
      pan: userPanNumber,
      gender: genderValue,
      maritalStatus: maritalValue,
      mothersName: motherName,
      correspondenceAddress: coAddress,
      correspondenceState: coState,
      correspondenceCity: coCity,
      correspondencePincode: coPinCode,
      type: "pan",
      permanentCity:perCity,
      permanentState:perState,
      permanentPincode:perPinCode,
      permanentAddress:perAddress,
      name: panUserDetails?.fullName,
      channel: channelUrl,
      subChannel,
      txnId: mi_u,
      kycId,
      dob:panUserDetails?.dob || dobOVD || "",
      fatherName,
    });

    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}/customer/customer-kyc`,
      headers: {
        "Content-Type": "application/json",
        txnId: localStorage.getItem("txnId") === "null" ?? null,
      },
      // data: data,
      data: {enc_request: encrypt(data)},
    };
    axios(config)
      // .then(function (response) {
      //   if (response.data.success === true) {
      //     dispatch(activeStepper(3));
      //     navigate("/Success", {
      //       state: {
      //         kyc_ref_id: response?.data?.data?.kyc_id,
      //         successUrl: response?.data?.data?.success_url,
      //         typeOfKyc: "pan",
      //       },
      //     });
      //   }
      // })
      .then(function (response) {
        let decResponse= decrypt(response?.data?.enc_response)
        console.log("PAN CARD FETCH DETAIL RESPONSE --",decResponse);
        if (decResponse?.success === true) {
          dispatch(activeStepper(3));
          navigate("/Success", {
            state: {
              kyc_ref_id: decResponse?.data?.kyc_id,
              successUrl: decResponse?.data?.success_url,
              typeOfKyc: "pan",
            },
          });
        }
      })
      .catch(function (error) {
        let decResponse= decrypt(error.response?.data?.enc_response)
        console.log("PAN CARD FETCH DETAIL RESPONSE --",decResponse.message);
        setErrorMessage(decResponse.message || "Something went wrong ")
        console.log("PAN CARD FETCH DETAIL FAILED --",error);
      });
  };

  const AddressHandler = () => {
    setAddressActive(!addressActive);
    setCheckboxActive(!checkboxActive);
  };
  const kycHandler = () => {
    console.log("KYC-HANDLER")
    const emptyFieldName = formValidator(panUserDetails?.isAryaLiteServiceUsed ? fieldArrayBOTH : fieldArraySINZY );
    const coEmptyFieldName = formValidator(correspondenceFieldArray);

    if (emptyFieldName) {
      setError("Please fill this detail : " + emptyFieldName);
      dispatch(showGlobalError("Please fill this detail : " + emptyFieldName));
      return;
    }
    if (!checkboxActive && coEmptyFieldName) {
      setCoError("Please fill this detail : " + coEmptyFieldName);
      dispatch(
        showGlobalError("Please fill this detail : " + coEmptyFieldName)
      );
      return;
    }

    gaFunction("PAN (CKYC)", "PAN (CKYC) continue cliked");
    let isInIf = false;
console.log("checkboxActive",checkboxActive)
console.log("IsCheckbox",IsCheckbox)
    if (
      checkboxActive &&
      motherName.length !== 0 &&
      fatherName?.length !== 0 &&
      genderValue !== "" &&
      maritalValue !== "" &&
      perAddress.length > 0 &&
      perState.length > 0 &&
      perCity.length > 0 &&
      perPinCode.length > 0 
    ) {
      isInIf = true;
console.log("record found in permaman")
      if (OEM_List.includes(channelUrl)) {
        skipDueDeligence();
      } else {
        navigate("/income", {
          state: {
            isRouteAllowed: true,
            type: "pan",
          },
        });
        dispatch(
          customerData({
            gender: genderValue,
            maritalStatus: maritalValue,
            mothersName: motherName,
            fatherName,
            correspondenceAddress: coAddress,
            correspondenceState: coState,
            correspondenceCity: coCity,
            correspondencePincode: coPinCode,
            // START add for 
            permanentCity:perCity,
            permanentState:perState,
            permanentPincode:perPinCode,
            permanentAddress:perAddress,
            // END
            name:panUserDetails?.fullName,
            dob:panUserDetails?.dob || dobOVD || "",
            // manualKYC:panUserDetails?.fatherName !==fatherName
          })
        );
      }

      dispatch(activeStepper(2));
    } else {
      setError(true);
    }
  
    if (
      !checkboxActive &&
      coAddress.length > 0 &&
      coState.length > 0 &&
      coCity.length > 0 &&
      coPinCode.length > 0 &&
      motherName.length !== 0 &&
      fatherName?.length !== 0 &&
      genderValue !== "" &&
      maritalValue !== "" &&
      IsCheckbox
    ) {
      console.log("record found in correspondennce")
      isInIf = true;
      if (OEM_List.includes(channelUrl)) {
        skipDueDeligence();
      } else {
        navigate("/income", {
          state: {
            isRouteAllowed: true,
            type: "pan",
          },
        });
        dispatch(
          customerData({
            gender: genderValue,
            maritalStatus: maritalValue,
            mothersName: motherName,
            fatherName,
            correspondenceAddress: coAddress,
            correspondenceState: coState,
            correspondenceCity: coCity,
            correspondencePincode: coPinCode,
            //ARYA START
            permanentCity:perCity,
            permanentState:perState,
            permanentPincode:perPinCode,
            permanentAddress:perAddress,
            //ARYA END
            name:panUserDetails?.fullName,
            dob:panUserDetails?.dob || dobOVD || "",
            userEditedAddress: isEdit,
            // manualKYC:panUserDetails?.fatherName !==fatherName
          })
        );
      }
      dispatch(activeStepper(2));
    } else {
      setError("Please provide your mother name, Gender and Marital Status");
    }
 
   
   
    if (!isInIf) {
      alert("Please fill all fields");
    }
  };
  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const yesHandler = () => {
    setOpenDrawer(false);
  };

  const noHandler = () => {
    navigate("/kyc");
  };

  const checkBoxHandler = () => {
    setIsCheckbox((prev) => !prev);
  };

  // const editHandler = () => {
  //   setIsEditable((prev) => !prev);
  // };

  const maskDetails = (address) =>{
    
    if (!address) {
      return "";
  }
    if(address.length<=3){
      return address
   }
let changedAddress=address.split(" ").map((str,index)=>{
    let pincodeIndex=str.search(/\d{6}/)
    if(pincodeIndex !==-1){
          let pincode =str.slice(pincodeIndex,6)
          console.log("pincode",pincode)
          let splicedAddress=address.split(" ")
        let start=str.slice(0,3)
        let end=Array.from(str.slice(3)).map(char=> char.replace(char,"*")).join("")
        return start+end
    }else{
        let end=Array.from(str).map(char=> char.replace(char,"*")).join("")
        return  index==0 || index===1 ? str : end
    }
    }).join(" ")
    return changedAddress
}

  useEffect(() => {
    // disable browser button
    return () => {
      setTimeout(() => {
        window.history.forward();
      }, 0);
    };
  }, []);

  return (
    <>
      {isRouteAllowed && (
        <div className="h-full flex flex-col">
          <MsHome>
            <BannerImage>
              <LogoContainer />
            </BannerImage>
            <div className="bg-secondaryH h-full">
              <BottomCard>
                <div className="h-max md:h-full w-full  z-0 last:mb-[15vh] md:last:mb-0">
                  <div className="hidden md:block md:h-[30%]">
                    <div className="p-[10px] md:p-0"></div>
                    <label className="md:text-xl">
                      <b>KYC Document</b>
                    </label>
                    <p className="hidden md:block md:text-sm">
                      let's fetch your kyc details using
                    </p>
                    <div className="p-[10px] md:p-[7px]"></div>
                    <div className="md:w-[50%] md:mb-5">
                      <Tab isDisabled />
                    </div>
                  </div>
                  <div className="  md:w-[100%] md:h-[50%] overflow-scroll md:rounded-md  md:p-[0px_16px_5px_0px]">
                    <div className=" p-2 md:p-0">
                      <label>
                        <b>Name</b>
                      </label>
                      <div className="flex items-center p-2">
                        <div className="px-4">
                          <BiUser size={25} />
                        </div>
                        <div className="capitalize">
                          <span>{panUserDetails?.fullName || "NA"}</span>
                        </div>
                      </div>
                      <div className="flex gap-5">
                        <CommonDropDown
                          options={gender}
                          selected={genderValue}
                          label="Gender"
                          setSelected={setGenderValue}
                        />
                        <CommonDropDown
                          options={maritalStatus}
                          label="Marital Status"
                          selected={maritalValue}
                          setSelected={setMaritalValue}
                        />
                      </div>
                      <div className="mb-2">
                        <label className="font-bold">Mother Name</label>
                        <TextInput
                          placeholder="Mother Name"
                          value={motherName}
                          onChange={(e) => setMotherName(e.target.value)}
                          width={"md:w-[292px]"}
                        />
                      </div>
           
                        <div className="mb-2">
                          <label className="font-bold">Father Name</label>
                          <TextInput
                            placeholder="Father Name"
                            value={fatherName}
                            onChange={(e) => {
                              setfatherName(e.target.value)
                            }
                            }
                            width={"md:w-[292px]"}
                          />
                        </div>
                    
                      <p className="text-red-500 text-sm">{error}</p>
                      <label>
                        <b>Permanent Address</b>
                      </label>
                      
                      {panUserDetails?.isAryaLiteServiceUsed !==true ? 
                      <div className="flex flex-row justify-start p-2">
                        <div className="flex flex-col px-4">
                          <MdOutlineLocationOn size={25} />
                          </div>
                          <div className="flex justify-between">
                            <p>{maskDetails(panUserDetails?.permanentAddress) || "NA"}</p>
                          </div>
                        </div> 
                        : 
                        <div className="main_address_container mt-5">
                          <div className="text_iput">
                            <TextInput
                              label={"Address"}
                              id="permanent_address"
                              placeholder={"ex.  ABC Apt, Mumbai-400001"}
                              onChange={(e) => 
                                {
                                  setPerAddress(e.target.value);
                                  setIsEdit(true)
                                }
                              }
                              // disabled={true}
                              value={perAddress}
                            />
                          </div>
                          <div className="flex flex-row justify-between items-center gap-4">
                            <TextInput
                              label={"State"}
                              id="permanent_state"
                              onChange={(e) => 
                                {
                                  setPerState(e.target.value);
                                  setIsEdit(true)
                                }
                              }
                              value={perState}
                              placeholder="ex. Maharashtra"
                            />
                            <TextInput
                              label={"City"}
                            id="permanent_city"
                            onChange={(e) => 
                             { 
                              setPerCity(e.target.value);
                              setIsEdit(true)
                            }
                            }
                            value={perCity}
                            placeholder="ex. Pune"
                          />
                        </div>
                        <div className="pincode w-[47%]">
                          <TextInput
                            type="tel"
                            id="permanent_number"
                            maxlen={"6"}
                            label={"PinCode"}
                            placeholder={"ex. 400001"}
                            onChange={(e) => {
                              pinCodeValidator(e.target.value ,"per");
                              setIsEdit(true)
                            }}
                            value={perPinCode}
                          />
                        </div>
                        <p className="text-red-500 text-sm">{coError}</p>
                      </div>
                        }

                      {/* <div className="flex flex-row items-center gap-2 mb-2">
                        <ErrorOutlineIcon
                          // sx={{ color: "red" }}
                          className="text-primaryH"
                          fontSize="small"
                        />
                        <p className="text-sm flex items-center text-primaryH">
                          Add Correspondence address option as requested.
                        </p>
                      </div> */}
                      <div className="flex items-center mt-[7px]">
                        <input
                          id="link-checkbox"
                          type="checkbox"
                          value=""
                          checked={checkboxActive}
                          className="w-5 h-5 rounded border-gray-300 text-primaryH ring-0 focus:ring-0"
                          onChange={AddressHandler}
                        />
                        <label
                          htmlFor="link-checkbox"
                          className="ml-2 text-sm font-medium text-black "
                        >
                          Use this as the correspondence address
                        </label>
                      </div>
                    </div>
                    {addressActive && (
                      <div className="main_address_container mt-5">
                        <label className="font-bold">
                          Correspondence Address
                        </label>
                        <div className="text_iput">
                          <TextInput
                            label={"Address"}
                            id="correspondence_address"
                            placeholder={"ex.  ABC Apt, Mumbai-400001"}
                            onChange={(e) => setCoAddress(e.target.value)}
                            value={coAddress}
                          />
                        </div>
                        <div className="flex flex-row justify-between items-center gap-5">
                          <TextInput
                            label={"State"}
                            id="correspondence_state"
                            onChange={(e) => setCoState(e.target.value)}
                            value={coState}
                            placeholder="ex. Maharashtra"
                          />
                          <TextInput
                            label={"City"}
                            id="correspondence_city"
                            onChange={(e) => setCoCity(e.target.value)}
                            value={coCity}
                            placeholder="ex. Pune"
                          />
                        </div>
                        <div className="pincode w-[47%]">
                          <TextInput
                            type="tel"
                            id="correspondence_number"
                            maxlen={"6"}
                            label={"PinCode"}
                            placeholder={"ex.  400001"}
                            onChange={(e) => {
                              pinCodeValidator(e.target.value , "co");
                            }}
                            value={coPinCode}
                          />
                        </div>
                        <p className="text-red-500 text-sm">{coError}</p>
                        <div className="flex items-center mt-2">
                          <input
                            id="link-checkbox"
                            type="checkbox"
                            value=""
                            checked={IsCheckbox}
                            onClick={checkBoxHandler}
                            className="w-5 h-5 rounded border-gray-300 text-red-600 ring-0 focus:ring-0"
                          />
                          <label
                            htmlFor="link-checkbox"
                            className="ml-2 text-sm font-medium text-black mt-2"
                          >
                            I confirm that this address is correct as per my
                            knowledge
                          </label>
                        </div>
                      </div>

                      // <div className="mt-[21px] ">
                      //   <AddressInput
                      //     onFocus={onTextInputFocusHndler}
                      //     onBlur={onTextInputBlurHndler}
                      //   />
                      // </div>
                    )}
                    {openDrawer && (
                      <CommonDrawer
                        onClickHandler={closeDrawer}
                        onProceed={yesHandler}
                        onDenied={noHandler}
                        warnMsg={panUserDetails?.isAryaLiteServiceUsed}
                      />
                    )}
                    {errorMessageDrawer && 
<ErrorBottomDrawer
            errorMessage={errorMessage}
            onClickHandler={()=>setErrorMessageDrawer(false)}
          />}
                  </div>
                  <div className="md:w-[235px] hidden md:block md:mr-auto  ">
                    <CommonButton
                      arrow
                      filled
                      onClickHandler={kycHandler}
                      label="Continue"
                      id="pan-details-continue-button"
                    />
                    {/* <CommonButton label="Use Another Address" location /> */}
                  </div>
                </div>
              </BottomCard>
            </div>
            {showBottomButtons && <BottomButtons kycHandler={kycHandler} />}
          </MsHome>
        </div>
      )}
    </>
  );
};

export default PanCardFetchDetailsFrame;

function BottomButtons({ kycHandler }) {
  return (
    <div className="fixed bottom-0 short:p-2 p-4 w-full bg-white rounded-sm border shadow-[2px_-4px_16px_-10px_rgba(0,0,0,0.75)] z-10 space-y-3 md:hidden">
      <CommonButton label="Continue" arrow filled onClickHandler={kycHandler} />
      {/* <CommonButton label="Use Another Address" location /> */}
    </div>
  );
}
