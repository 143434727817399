import { fetchShortDetailsForPartnerDigilocker, requestDigilockerURLForPartnerRedirect } from "../../services/adhaar-digilocker-service";

export async function requestDigilockerLinkWithRequestId(data2) {
  let response = await requestDigilockerURLForPartnerRedirect(
    data2
  );

  if (response?.success) {
    return response.data;
  }

  return false;
}

export async function getShortDetailsOfDigilockerKYCWithRequestID(
  data1
) {
  let response = await fetchShortDetailsForPartnerDigilocker(
    data1
  );

  if (response?.success) {
    return response.data;
  }

  return false;

}
