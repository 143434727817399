import { apiInstance } from "./networking";

export const fetchAdhaarDetails = async (payload) => {
  const { data = {} } = await apiInstance.post(
    "customer/associate-cusomer-and-fetch-address-details",
    payload
  );
  return data;
};

export const loadPANDetails = async (payload) => {
  const { data = {} } = await apiInstance.post("", payload);
  return data;
};

export const fetchShortDetailsAndRedirect = async (payload, params) => {
  const { data = {} } = await apiInstance.post(
    `partner/digilocker/shortDetail/${params.requestId}`,
    payload
  );
  return data;
};

export const requestDigilockerURLForPartnerRedirect = async (
  data2
) => {
  const { data = {} } = await apiInstance
    .get(`partner/digilocker/${data2?.requestId}`, {
      headers: {
        enctoken: data2?.authToken || null,
      },
    })
    .catch((err) => {
      return { data: err?.response?.data || false };
    });

  return data;
};
export const fetchShortDetailsForPartnerDigilocker = async (
  data1
) => {
  const { data = {} } = await apiInstance
    .get(`partner/digilocker/shortDetail/${data1?.returnedRequestId}`, {
      headers: {
        enctoken: data1?.authToken || null,
      },
    })
    .catch((err) => {
      return { data: err?.response?.data || false };
    });

  return data;
};
